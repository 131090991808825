import axios from "axios";
import qs from "qs"; //将json数据form化
import { Message } from "element-ui";
import router from "../router";

// axios.defaults.baseURL = "http://localhost:8080";
axios.defaults.baseURL = "https://artstore.lvchenkeji.com/mapi";
axios.defaults.timeout = 5000; // 5秒
axios.defaults.shouldRetry = false;

/**
 * 封装http请求
 */
export function http(options = {}) {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: options.url,
            data: options.data,
        })
            .then((response) => {
                // 拦截未登录的错误代码
                if (response.data.code == 501) {
                    router.push({
                        path: "/login",
                        query: { redirect: router.currentRoute.fullPath }, //从哪个页面跳转
                    });
                    this.$message({
                        message: "账号未登录",
                        type: "error",
                    });
                    return;
                } else if (response.data.code != 1) {
                    Message.error("提示：" + response.data.message + " (" + response.data.code + ")");
                    return;
                }
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

//http request 拦截器
axios.interceptors.request.use(
    (config) => {
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        config.headers["user_token"] = config.token || sessionStorage.getItem("user_token"); // Token
        config.data = qs.stringify(config.data || {}); //qs序列化参数
        console.log("request->", config.url, config.data);
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

//http response 拦截器
axios.interceptors.response.use(
    (response) => {
        console.log("response->", response.config.url, response.data);
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export function cdn() {
    return "https://cdn.ziison.com/";
}
