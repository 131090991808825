import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/index", //重定向
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login.vue"),
    },
    {
        path: "/index",
        name: "index",
        redirect: "/home/welcome",
        meta: { title: "首页" },
        component: () => import("../views/index.vue"),
        children: [
            {
                path: "/home/welcome",
                name: "home-welcome",
                meta: { title: "欢迎页" },
                component: () => import("../views/home/welcome.vue"),
            },
            // 运营管理
            {
                path: "/operate/lunbo",
                name: "operate-lunbo",
                meta: { title: "活动公告" },
                component: () => import("../views/home/operate/lunbo.vue"),
            },
            {
                path: "/operate/lunbo-create",
                name: "operate-lunbo-create",
                meta: { title: "新增内容" },
                component: () => import("../views/home/operate/lunbo-create.vue"),
            },
            {
                path: "/operate/user",
                name: "operate-user",
                meta: { title: "用户管理" },
                component: () => import("../views/home/operate/user.vue"),
            },
            {
                path: "/operate/report",
                name: "operate-report",
                meta: { title: "运营报表" },
                component: () => import("../views/home/operate/report.vue"),
            },
            {
                path: "/order/list",
                name: "order-list",
                meta: { title: "订单管理" },
                component: () => import("../views/home/order/list.vue"),
            },
            // 作者管理
            {
                path: "/artist/create",
                name: "artist-create",
                meta: { title: "新增类别" },
                component: () => import("../views/home/artist/create.vue"),
            },
            {
                path: "/artist/list",
                name: "artist-list",
                meta: { title: "分类列表" },
                component: () => import("../views/home/artist/list.vue"),
            },
            // 作品管理
            {
                path: "/item/create",
                name: "item-create",
                meta: { title: "新增商品" },
                component: () => import("../views/home/item/create.vue"),
            },
            {
                path: "/item/list",
                name: "item-list",
                meta: { title: "商品列表" },
                component: () => import("../views/home/item/list.vue"),
            },
            // 系统管理
            {
                path: "/admin/create",
                name: "admin-create",
                meta: { title: "新增管理员" },
                component: () => import("../views/home/admin/create.vue"),
            },
            {
                path: "/admin/list",
                name: "admin-list",
                meta: { title: "管理员列表" },
                component: () => import("../views/home/admin/list.vue"),
            },
            {
                path: "/operate/contact",
                name: "operate-contact",
                meta: { title: "联系方式" },
                component: () => import("../views/home/operate/contact.vue"),
            },
            {
                path: "/operate/contact-edit",
                name: "operate-contact-edit",
                meta: { title: "联系方式" },
                component: () => import("../views/home/operate/contact-edit.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(function(to, from, next) {
    document.title = "后台管理系统";
    if (!sessionStorage.getItem("user_token")) {
        if (to.path != "/login") {
            next("/login");
        }
    }
    next();
});

export default router;
