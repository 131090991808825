import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

/* Element UI */
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

/* Axios */
import { http,cdn } from "./utils/http.js";
Vue.prototype.$http = http;
Vue.prototype.$cdn = cdn;

/* Global CSS */
import "./assets/css/normalize.css";
import "./assets/css/common.css";

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
